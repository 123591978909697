import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import React from 'react';

import { parseUrlQueryToRecord } from '@/base/query_string/parse_url_query_to_record';
import { InstallPageResults } from '@/features/base/page_types';

/**
 * Our previous DI system had access to Router because of lazy installation of pages.
 * This function allows us to continue the same hackery, but as we transition to NextJS app router,
 * we will want to split dependencies along server/client line and make server installation pure of client code.
 */
export function createNextAppPage(install: () => InstallPageResults) {
  const Page = dynamic(async () => (await install()).Page);
  const NextPage = () => {
    const searchParams = useSearchParams();
    return <Page query={parseUrlQueryToRecord(searchParams)} />;
  };
  return NextPage;
}
